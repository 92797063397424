@use "sass:meta" as ---kha1f6wtc2;@use 'sass:math';

.t-calendar {
  line-height: 1;
  font-size: rem-calc(13);

  @include grid-column-margin;

  table {
    width: 100%;
  }

  td {
    width: percentage(math.div(1, 7));
  }

  .sat,
  .sun {
    color: $alert-color;
  }

  .monthrow {
    padding: $global-padding;
    font-size: $global-font-size;
    text-align: center;
  }

  .weekrow {
    text-align: right;

    th {
      padding: 1rem 0 .5rem;
      font-weight: $global-weight-normal;
      color: $dark-gray;
      font-size: rem-calc(12);
    }
  }

  .dayrow {
    td {
      padding: math.div($global-padding, 2);
      text-align: right;
    }

    .selected {
      font-weight: $global-weight-bold;
      background-color: $medium-gray;
    }
  }

  .monthnav {
    line-height: 18px;
    width: 240px;

    span {
      float: left;
      padding: 0 0 $global-padding;
    }

    .prev,
    .next {
      width: 120px;
    }

    .next {
      text-align: right;
    }

    .prev {
      text-align: left;
    }
  }
}

.content {
  .t-calendar {
    @include xy-cell(math.div(1, 3), $breakpoint: 'auto');

    margin-bottom: 0;

    .monthrow {
      th {
        background-color: unset;
        font-size: $global-font-size;
        font-weight: $global-weight-bold;
      }
    }

    .weekrow {
      th {
        background-color: $alice-blue;
        color: $dark-gray;
        height: $global-margin;
        padding: .5rem 0;
      }
    }

    .dayrow {
      td {
        font-size: $global-font-size;
      }
    }
  }
}

;@include ---kha1f6wtc2.load-css("sass-embedded-legacy-load-done:3734");