@use "sass:meta" as ---kha1f6wtc2;@use 'sass:math';

.news-header {
  border-bottom: 1px solid $medium-gray;
  margin-bottom: $global-margin;
  padding-bottom: math.div($global-padding, 2);

  a {
    color: $black;
    text-decoration: none !important;

    &:visited {
      color: $black;
    }

    &:hover {
      color: $anchor-color-hover;
    }
  }

  @include small-h;
}

;@include ---kha1f6wtc2.load-css("sass-embedded-legacy-load-done:3702");