@use "sass:meta" as ---kha1f6wtc2;.news-timeline {
  @include breakpoint(small only) {
    display: block;
  }

  ul {
    list-style: none;
    margin: 0;

    li {
      margin-bottom: $global-margin;
      line-height: 1.4;
    }

    .pub_date {
      @include show-for(medium);

      font-size: rem-calc(14);
      color: $light-black;
      display: inline-block;
      margin-right: 5px;

      &.flags {
        background-color: transparentize($tangerine-yellow, .5);
      }
    }

    @include small-l;
  }
}

;@include ---kha1f6wtc2.load-css("sass-embedded-legacy-load-done:3722");